import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import { usePrefersReducedMotion } from '@hooks';
// import { email } from '@config';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--slate);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 540px;
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = <h1>Merhaba! Ben, </h1>;
  const two = <h2 className="big-heading">Osman Demirci</h2>;
  const three = <h1 className="big-heading">Developer | Webmaster | DevOps</h1>;
  const four = (
    <>
      <p>10 Yaşımdan beri <b>Siber Güvenlik</b> ile ilgilenmekteyim. <b>Web Pentestration</b> ile yakından ilgileniyorum. Bunlara ek olarak Sistem, Network ve Siber Güvenlik Uzmanlığı eğitimleri almış olan Kelepçe,
        Bug Bounty Ödül Avcılığı kapsamında birçok tanınmış şirketten ödül aldım. Şu anda bilişim faaliyet topluluklarında <b>Bilgi Güvenliği ve Sızma Testleri</b> işlerini gönüllü olarak yapmaktayım.</p>
    </>
  );
  const five = (
    <a
      className="email-link"
      href="mailto:osmanndmrc@gmail.com"
      target="_blank"
      rel="noreferrer">
      Bana Ulaşın
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      {prefersReducedMotion ? (
        <>
          {items.map((item, i) => (
            <div key={i}>{item}</div>
          ))}
        </>
      ) : (
        <TransitionGroup component={null}>
          {isMounted &&
            items.map((item, i) => (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={{ transitionDelay: `${i + 1}00ms` }}>{item}</div>
              </CSSTransition>
            ))}
        </TransitionGroup>
      )}
    </StyledHeroSection>
  );
};

export default Hero;
